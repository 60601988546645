<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
     @submit.prevent="submit"
  >
    <v-col md="12">
      <v-row justify="center">
        <v-col md="5">
          <div v-if="!getAdmin">
            <v-col md="12">
              <v-row justify="center">
                <span class="title-toggle">Escolha abaixo a senha que deseja alterar:</span>
              </v-row>
            </v-col>
            <v-row justify="center" class="mb-md-8">
              <v-col md="12" cols="12">
                <v-btn class="btn-toggle mr-md-5 mb-2 mb-md-0"
                  :outlined="styleWeb" @click="showWeb" color="#00C1C1" dark>Senha Portal</v-btn>
                <v-btn class="btn-toggle" v-if="digitalAccount && digitalAccount.hasPassword"
                  @click="showAccount" :outlined="styleAccount" dark  color="#D70472">
                  Senha Conta Digital</v-btn>
              </v-col>
            </v-row>
          </div>
          <v-text-field
            v-if="getAdmin"
            v-model="email"
            label="E-mail"
            readonly
          ></v-text-field>
          <span v-if="!getAdmin && webPassword" class="web-title">Senha Portal</span>
          <div v-if="getAdmin || webPassword">
            <v-text-field
              class="password mb-5"
              :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              v-model="password"
              label="Senha atual"
              placeholder="Digite aqui sua senha atual"
              :rules="oldPasswordRules"
              required
              :readonly="enable"
            ></v-text-field>
            <v-text-field
              class="newPassword mb-5"
              :append-icon="showNewPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="showNewPassword ? 'text' : 'password'"
              @click:append="showNewPassword = !showNewPassword"
              v-model="newPassword"
              :rules="passwordRules"
              label="Nova senha"
              placeholder="Digite aqui sua nova senha"
              required
              :readonly="enable"
            ></v-text-field>
            <v-text-field
              class="confirmPassword mb-5"
              v-model="confirmPassword"
              :append-icon="showConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="showConfirmPassword ? 'text' : 'password'"
              @click:append="showConfirmPassword = !showConfirmPassword"
              :rules="passwordConfirmationRules"
              label="Confirmar senha"
              placeholder="Preencher igual ao campo acima"
              required
              :readonly="enable"
            ></v-text-field>
            <v-row justify="end">
              <v-col md="6" cols="12" class="button-web">
                <v-btn class="btn-reset" :disabled="!valid"
                rounded block @click="validate">Alterar</v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-if="accountPassword" class="accountPassword">
            <span class="account-title">Senha Conta Digital</span>
            <v-text-field
              class="digitalPassword mb-5"
              :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              v-model="passwordDigital"
              label="Senha atual"
              v-mask="'######'"
              placeholder="Digite aqui sua senha atual"
              :rules="digitalCurrentPassword"
              required
              :readonly="enableDigital"
            ></v-text-field>
            <v-text-field
              class="digitalNewPassword mb-5"
              :append-icon="showNewPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="showNewPassword ? 'text' : 'password'"
              @click:append="showNewPassword = !showNewPassword"
              v-model="newDigitalPassword"
              v-mask="'######'"
              :rules="digitalNewPassword"
              label="Nova senha"
              placeholder="Digite aqui sua nova senha"
              required
              :readonly="enableDigital"
            ></v-text-field>
            <v-text-field
              class="digitalConfirmPassword mb-5"
              v-model="confirmDigitalPassword"
              :append-icon="showConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="showConfirmPassword ? 'text' : 'password'"
              @click:append="showConfirmPassword = !showConfirmPassword"
              v-mask="'######'"
              :rules="digitalConfirmPassword"
              label="Confirmar senha"
              placeholder="Preencher igual ao campo acima"
              required
              :readonly="enableDigital"
            ></v-text-field>
            <v-row justify="end">
              <v-col md="6" cols="12" class="button-account">
                <v-btn class="btn-reset" :disabled="!valid"
                rounded block @click="validateDigital">Alterar</v-btn>
              </v-col>
            </v-row>
          </div>
            <ModalPassword :success ="getPasswordSuccess" :erro="getPasswordError"
              :erroMessage="getPasswordError" v-if="showModal" v-on:closed="showModal = false" />
            <ModalPassword :success ="getPasswordSuccessDigital" :erro="getPasswordErrorDigital"
              :erroMessage="getPasswordErrorDigital" v-if="showModalDigital"
              v-on:closed="showModalDigital = false" />
        </v-col>
      </v-row>
    </v-col>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';
import ModalPassword from './ModalPassword.vue';

export default {
  components: {
    ModalPassword,
  },
  directives: { mask },
  data() {
    return {
      enable: false,
      enableDigital: false,
      valid: true,
      showModal: false,
      showModalDigital: false,
      webPassword: true,
      accountPassword: false,
      styleWeb: false,
      styleAccount: true,
      email: '',
      newPassword: '',
      newDigitalPassword: '',
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      password: '',
      passwordDigital: '',
      confirmPassword: '',
      confirmDigitalPassword: '',

      passwordConfirmationRules: [
        valueInput => !!valueInput || 'Senha de confirmação obrigatória',
        () => this.newPassword === this.confirmPassword
          || 'Senhas devem ser iguais',
      ],
      passwordRules: [
        valueInput => !!valueInput || 'Senha obrigatória',
        valueInput => (valueInput && valueInput.length >= 8)
          || 'Senha deve ter até 8 caracteres ou mais',
        valueInput => (valueInput && valueInput.match(/\W/))
          || 'Senha deve ter ao menos um caracter especial',
        valueInput => (valueInput && valueInput.match(/[A-Z]/))
          || 'Senha deve ter ao menos um caracter maiúsulo',
        valueInput => (valueInput && valueInput.match(/[0-9]/))
          || 'Senha deve ter ao menos um número',
      ],
      oldPasswordRules: [
        valueInput => !!valueInput || 'Senha obrigatória',
      ],
      digitalCurrentPassword: [
        valueInput => !!valueInput || 'Senha obrigatória',
      ],
      digitalNewPassword: [
        valueInput => !!valueInput || 'Senha obrigatória',
        valueInput => (valueInput && valueInput.length === 6)
          || 'Senha deve ter 6 dígitos',
        valueInput => (valueInput && valueInput.match(/^\d+$/))
          || 'Senha deve ter somente números',
        valueInput => (valueInput && valueInput.match(/^(?!.*?(\w)\1{5}).*$/gm))
          || 'Senha deve ter números diferentes',
        valueInput => (valueInput && ('0123456789012345789').indexOf(valueInput) === -1)
          || 'Senha deve ter números não sequenciais',
      ],
      digitalConfirmPassword: [
        valueInput => !!valueInput || 'Senha de confirmação obrigatória',
        () => this.newDigitalPassword === this.confirmDigitalPassword
          || 'Senhas devem ser iguais',
      ],
    };
  },
  methods: {
    ...mapActions(
      {
        setConsultRegistration: 'Registration/setConsultRegistration',
        changePassword: 'Registration/changePassword',
        changeDigitalPassword: 'DigitalAccount/changePassword',
      },
    ),
    ...mapMutations({
      cleanMessagem: 'Registration/CLEAN_PASSWORD_MESSAGE',
      cleanMessagemDigital: 'DigitalAccount/CLEAN_PASSWORD_MESSAGE',
    }),
    validate() {
      this.$refs.form.validate();
      if (this.newPassword && this.newPassword
      && this.confirmPassword && this.newPassword === this.confirmPassword) {
        this.$refs.form.validate();
        this.changePassword({
          password: this.password,
          newPassword: this.newPassword,
        });
      }
    },
    validateDigital() {
      this.$refs.form.validate();
      if (this.newDigitalPassword && this.newDigitalPassword
      && this.confirmDigitalPassword && this.newDigitalPassword === this.confirmDigitalPassword) {
        this.$refs.form.validate();
        this.changeDigitalPassword({
          password: this.passwordDigital,
          newPassword: this.newDigitalPassword,
        });
      }
    },

    showAccount() {
      this.accountPassword = true;
      this.webPassword = false;
      this.styleWeb = true;
      this.styleAccount = false;
      this.password = '';
      this.confirmPassword = '';
      this.newPassword = '';
    },
    showWeb() {
      this.accountPassword = false;
      this.webPassword = true;
      this.styleWeb = false;
      this.styleAccount = true;
      this.newDigitalPassword = '';
      this.passwordDigital = '';
      this.confirmDigitalPassword = '';
    },
  },
  computed: {
    ...mapGetters(
      {
        getLogin: 'Auth/getLogin',
        getPasswordSuccess: 'Registration/getPasswordSuccess',
        getPasswordError: 'Registration/getPasswordError',
        getPasswordSuccessDigital: 'DigitalAccount/getPasswordSuccess',
        getPasswordErrorDigital: 'DigitalAccount/getPasswordError',
        digitalAccount: 'DigitalAccount/getAccount',
      },
    ),
  },
  watch: {
    getPasswordSuccess() {
      this.enable = true;
      this.valid = false;
      this.showModal = true;
    },
    getPasswordError() {
      this.showModal = true;
    },
    getPasswordSuccessDigital() {
      this.enableDigital = true;
      this.valid = false;
      this.showModalDigital = true;
    },
    getPasswordErrorDigital() {
      this.showModalDigital = true;
    },
  },

  async created() {
    await this.setConsultRegistration();
    this.email = this.getConsultRegistration.email;
  },
  destroyed() {
    this.cleanMessagem();
    this.cleanMessagemDigital();
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/commons/changePassword.scss';
</style>
