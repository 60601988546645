<template>
  <v-row class="rowDialog">
    <v-dialog v-model="dialog" v-if="success || erro" :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="430" class="dialog">
      <v-card class="contentCard">
        <v-card-title class="headline">
          <v-col cols="10" class="modal-title">
            Minhas Senhas
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="icon" cols="1">
            <v-icon  @click="closeModal" class="close-icon">
              mdi-window-close
            </v-icon>
          </v-col>
        </v-card-title>

          <v-divider  class="mx-6 ml-6 divider divider-consult"></v-divider>

        <v-card-text class="modal-form">

          <v-row justify="center" class="success-text" v-if="success">
            <v-col cols="12">
              <v-row justify="center">
                <img class="imgSuccess mt-md-5 mt-12" src="@/assets/success.svg" />
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row justify="center">
                <v-col cols="10" md="6">
                  <p class="text-success mb-0">Sua senha foi atualizada com sucesso!</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row justify="center">
                <v-btn
                  rounded
                  outlined
                  color="#00C1C1"
                  @click="backHome"
                  class="btnBack">
                  Voltar para Home
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row justify="center">
                <v-btn
                  rounded
                  color="#8C8C8C"
                  outlined
                  @click="closeModal"
                  class="btnClose">
                  Fechar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>

          <v-row justify="center" class="success-text" v-if="erro">
            <v-col cols="12">
              <v-row justify="center">
                <img class="imgSuccess mt-md-5 mt-12" src="@/assets/erro.png" />
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row justify="center">
                <v-col cols="8">
                  <p class="text-error">Erro ao realizar alteração de senha</p>
                  <p class="subtext-error mb-0">{{erroMessage}}</p>
                  <!-- <p class="subtext-error mb-0">Por favor, tente novamente mais tarde</p> -->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row justify="center">
                <v-btn
                  rounded
                  color="#D70472"
                  outlined
                  @click="closeModal"
                  class="btnClose">
                  Fechar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  props: ['success', 'erro', 'erroMessage'],
  data() {
    return {
      dialog: true,
      loadingBtn: false,
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.closeModal();
        this.$emit('closed');
      }
    },
  },
  computed: {
    role() {
      return this.$store.getters['Auth/getRole'];
    },
  },
  methods: {
    async closeModal() {
      this.dialog = false;
    },
    backHome() {
      const home = 'home';
      return this.$router.push({ path: `/customer/${home}` });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/commons/modalPassword.scss';
</style>
